import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import cbtSessionsStyles from "./cbt-sessions.module.scss"

const IndexPage = () => {
  return (
    <Layout isHome={true}>
      <Head title="Home" />
      <h2>Welcome</h2>
      <p>
        I am an integrative Cognitive Behavioural Therapy (CBT) counsellor,
        working exclusively online. My role as a counsellor is to help you
        connect with how you want to be in the world and work towards putting
        that into practice. It’s a journey sure to encounter insight, obstacles,
        and change. <Link to="/cbt-sessions/">My approach</Link> uses CBT as the
        base of our work and brings in tools and perspectives from other
        approaches and philosophies such as Internal Family Systems (IFS) Therapy, Gestalt,
        Feminism, and Non-Violent Communication (NVC).
      </p>
      <blockquote className={cbtSessionsStyles.quoteBlock}>
        <p>
          <q>
            <cite>
              You don't have to control your thoughts. You just have to stop
              letting them control you.
            </cite>
          </q>{" "}
          <span className={cbtSessionsStyles.quoteAuthor}>Dan Millman</span>
        </p>
      </blockquote>
      <p>
        Taking the step to look for a therapist can be a daunting one, so I
        invite you to use the resources on this website to help address the
        fears, queries, and doubts you may have about therapy, CBT, and what
        working with me would be like. On this site you’ll find more detailed
        information{" "}
        <Link to="/cbt-sessions/">
          about CBT and my personal integrative approach
        </Link>
        , and some of your questions may be answered on the{" "}
        <Link to="/faq/">FAQ page</Link>. In the{" "}
        <Link to="/about-me/">About Me</Link> page I also include a short
        introductory video to bring my voice and personality to life and give
        you a better feel for who I am. Use the{" "}
        <Link to="/contact-me/">Contact Me</Link> page if you have more
        questions or want to talk about how we can work together.
      </p>
    </Layout>
  )
}

export default IndexPage
